<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { type HTMLAttributes, ref } from 'vue';

import { type MenuThemeOpenable } from '../types';

interface SubMenuClasses {
  button: MenuThemeOpenable,
  li: HTMLAttributes['class'],
  ul: HTMLAttributes['class'],
}

const props = defineProps<{
  selected?: boolean,
  classes: SubMenuClasses
}>();

// eslint-disable-next-line vue/no-setup-props-destructure
const open = ref(props.selected);
const onClick = () => { open.value = !open.value; };
</script>

<template>
  <li :class="classes.li">
    <button
      :class="[
        classes.button.class,
        selected ? classes.button.selected : classes.button.unselected,
        open ? classes.button.open : '',
        selected && open ? classes.button.openSelected : '',
        !selected && open ? classes.button.openUnSelected : '',
      ]"
      type="button"
      @click="onClick"
    >
      <slot name="title" v-bind="{ open, onClick }" />
    </button>
    <ul v-show="open" :class="classes.ul">
      <slot v-bind="{ open, onClick }" />
    </ul>
  </li>
</template>
