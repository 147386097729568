<script setup lang="ts">
const onRedirect = () => {
  window.location.href = '/';
};
</script>
<template>
  <div class=" max-w-md mx-auto text-center my-10">
    <Svg404 class="mb-2 mx-auto" />
    <h1 class="font-semibold text-2xl">
      Page not found
    </h1>
    <p class="my-4">
      We can't find the page you're looking for.
    </p>
    <slot />
    <div>
      <UButton @click="onRedirect">
        Return to dashboard
      </UButton>
    </div>
  </div>
</template>
