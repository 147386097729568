import { type SSRData } from '@urql/vue';

import getClient, { ssr } from '../graphql/pp/config/index';
import { getConfig } from '../config/constants';

const ssrKey = '__URQL_DATA__';

export default defineNuxtPlugin((nuxt) => {
  const { vueApp } = nuxt;

  // when app is created in browser, restore SSR state from nuxt payload
  if (process.client) {
    nuxt.hook('app:created', () => {
      ssr.restoreData(nuxt.payload[ssrKey] as SSRData);
    });
  }

  // when app has rendered in server, send SSR state to client
  if (process.server) {
    nuxt.hook('app:rendered', () => {
      // eslint-disable-next-line no-param-reassign
      nuxt.payload[ssrKey] = ssr.extractData();
    });
  }

  const client = getClient(getConfig(nuxt.$config).domains.api);
  nuxt.provide('urql', client);
  vueApp.provide('$urql', ref(client));
});
