// eslint-disable-next-line import/no-unresolved
import tailwindColors from '#tailwind-config/theme/colors';

export type ColorsAvailables = Omit<typeof tailwindColors,
  'inherit' | 'transparent' | 'currentColor' | '#000' | '#fff' | 'current' | 'black' | 'white'
  | 'attention' | 'negative' | 'positive' | 'sidebar'
>;
export type KeyColors = keyof ColorsAvailables;

export const colors = tailwindColors;
export function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const newHex = hex.replace(shorthandRegex, (_, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
  return result
    ? `${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(result[3], 16)}`
    : null;
}

export default defineNuxtPlugin({
  enforce: 'post',
  setup() {
    const appConfig = useAppConfig();

    const root = computed(() => {
      const primary: Record<string, string> | undefined = colors[appConfig.ui.primary as KeyColors];
      const gray: Record<string, string> | undefined = colors[appConfig.ui.gray as KeyColors];
      const colorsArray = Object
        .entries(primary || colors['gg-blue'])
        .map(([key, value]) => `--color-primary-${key}: ${hexToRgb(value)};`);
      const grayColors = Object.entries(gray || colors.cool).map(([key, value]) => `--color-gray-${key}: ${hexToRgb(value)};`);

      return `
        :root {
          ${colorsArray.join('\n')}
          --color-primary-DEFAULT: var(--color-primary-500);

          ${grayColors.join('\n')}
        }

        .dark {
          --color-primary-DEFAULT: var(--color-primary-400);
        }
        `;
    });

    if (process.client) {
      watch(root, () => {
        window.localStorage.setItem('nuxt-ui-root', root.value);
      });

      appConfig.ui.primary = window.localStorage.getItem('nuxt-ui-primary') || appConfig.ui.primary;
      appConfig.ui.gray = window.localStorage.getItem('nuxt-ui-gray') || appConfig.ui.gray;
    }
    if (process.server) {
      useHead({
        script: [
          {
            innerHTML: `
                if (localStorage.getItem('nuxt-ui-root')) {
                  document.querySelector('style#nuxt-ui-colors').innerHTML = localStorage.getItem('nuxt-ui-root')
                }`.replace(/\s+/g, ' '),
            type: 'text/javascript',
            tagPriority: -1,
          },
        ],
      });
    }
  },
});
