<script setup lang="ts">
import { getConfig } from '@/config/constants';

const constants = getConfig(useRuntimeConfig());

const { onLogout, fetching, success } = useLogout();
const isBeta = true;
const { user, setUser } = usePortalContact();
</script>
<template>
  <div
    v-if="fetching || success"
    class="fixed flex justify-center items-center w-full h-full z-10 backdrop-blur-sm "
  >
    <div class=" text-2xl font-semibold">
      Closing session ...
    </div>
  </div>
  <TermConditions
    :user="user"
    :loading="fetching"
    :on-logout="onLogout"
    :on-success="setUser"
  >
    <a
      href="#main"
      class="sr-only focus:not-sr-only focus:absolute top-1 left-1 !p-2 shadow-lg bg-white dark:bg-gray-900"
    >
      Skip to content
    </a>
    <div
      v-if="user.sessionImpersonation || isBeta"
      :class="{
        'pt-[4.5rem]': user.sessionImpersonation && isBeta,
        'pt-[2.25rem]': ((!user.sessionImpersonation && isBeta) || (user.sessionImpersonation && !isBeta)),
      }"
    >
      <div class="fixed top-0 z-[100] w-full">
        <div v-if="user.sessionImpersonation" class="bg-red-600 text-white p-2 text-sm w-full">
          You are impersonating this user. Please remember to <button type="button" class="underline hover:text-yellow-100" @click="onLogout">
            logout.
          </button>.
        </div>
        <div v-if="isBeta" class=" text-white bg-yellow-700 p-2 text-sm">
          Partner Portal is in beta testing.
        </div>
      </div>
    </div>
    <div class="font-sans min-h-screen bg-gg-blue-50 dark:bg-slate-900">
      <div v-if="constants.isLocal" class="absolute z-50 p-2">
        <small class="text-red-800 bold block xs:hidden">Min</small>
        <small class="text-red-800 bold hidden xs:block sm:hidden">XS</small>
        <small class="text-red-800 bold hidden sm:block md:hidden">Small (SM)</small>
        <small class="text-red-800 bold hidden md:block lg:hidden">Medium (MD)</small>
        <small class="text-red-800 bold hidden lg:block xl:hidden">Large (LG)</small>
        <small class="text-red-800 bold hidden xl:block xxl:hidden">XL</small>
      </div>
      <div class="sm:flex min-h-screen">
        <LayoutSidebar class="border-r dark:border-slate-700 w-80 p-5 hidden lg:flex" />
        <div class="flex-1 min-w-0">
          <header class="border-b bg-white dark:bg-slate-800 dark:border-slate-700 p-2 w-full  fixed z-10 sm:static sm:z-0 ">
            <LayoutHeader :on-logout="onLogout" class-mobile="lg:hidden" class=" max-w-screen-xl mx-auto" />
          </header>
          <main id="main" class="pl-4 pr-4 pb-4 pt-24 sm:pt-4">
            <div class=" max-w-screen-xl mx-auto">
              <slot name="content" />
            </div>
          </main>
        </div>
      </div>
    </div>
  </TermConditions>
</template>
