<script setup lang="ts">
const { partner } = usePartner();

</script>

<template>
  <aside
    id="sidebar"
    class="min-h-screen bg-sidebar-bg flex flex-col justify-between"
  >
    <div class="flex-1">
      <LayoutSidebarSchool class="mb-4" />
      <LayoutSidebarSchoolMenu :partner="partner" />
    </div>
    <LayoutSidebarPoweredBy
      class="mt-10 text-sidebar-text"
      logo-parent-class="text-sidebar-logo-color"
      :one-color="!!partner.partner.colors.logoOneColor"
    />
  </aside>
</template>
