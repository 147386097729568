import type {
  Variables, Cache, UpdatesConfig,
} from '@urql/exchange-graphcache';

import invalidateQuery from '../util/invalidateQuery';
import type {
  RentersCoverageConfirmationApproveMutation,
  RentersCoverageConfirmationAsPendingMutation,
  RentersCoverageConfirmationRejectMutation,
  RentersCoverageConfirmationSaveMutation,
} from '../../generated/graphql';

const rentersSnowflakeMutations: UpdatesConfig['Mutation'] = {
  rentersCoverageConfirmationAsPending: (resolver: RentersCoverageConfirmationAsPendingMutation, __: Variables, cache: Cache) => {
    if (resolver.rentersCoverageConfirmationAsPending) {
      invalidateQuery('snowflakeRentersPolicies', cache);
    }
  },
  rentersCoverageConfirmationSave: (resolver: RentersCoverageConfirmationSaveMutation, __: Variables, cache: Cache) => {
    if (resolver.rentersCoverageConfirmationSave) {
      invalidateQuery('snowflakeRentersPolicies', cache);
    }
  },
  rentersCoverageConfirmationReject: (resolver: RentersCoverageConfirmationRejectMutation, __: Variables, cache: Cache) => {
    if (resolver.rentersCoverageConfirmationReject) {
      invalidateQuery('snowflakeRentersPolicies', cache);
    }
  },
  rentersCoverageConfirmationApprove: (resolver: RentersCoverageConfirmationApproveMutation, __: Variables, cache: Cache) => {
    if (resolver.rentersCoverageConfirmationApprove) {
      invalidateQuery('snowflakeRentersPolicies', cache);
    }
  },
};

export default rentersSnowflakeMutations;
