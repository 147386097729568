import { cacheExchange as cacheExchangeURQL } from '@urql/exchange-graphcache';

import schema from '../../generated/graphql.schema';
import type {
  PartnerPortalContactFragmentFragment,
  SnowFlakeRefundAcknowledgmentVuFragmentFragment,
  SnowflakeRentersPolicyFragmentFragment,
  SnowflakeTuitionPolicyFragmentFragment,
} from '../../generated/graphql';
import portalContactMutations from './portalContact';
import zendeskMutations from './zendesk';
import rentersSnowflakeMutations from './rentersSnowflake';

const cacheExchange = cacheExchangeURQL({
  schema,
  updates: {
    Mutation: {
      ...portalContactMutations,
      ...zendeskMutations,
      ...rentersSnowflakeMutations,
    },
  },
  keys: {
    PaginatorInfo: () => null,
    PortalContactPaginator: () => null,
    PartnerRolePaginator: () => null,
    PartnerPortalContact: (data) => {
      const contact = data as PartnerPortalContactFragmentFragment;
      return `${contact.partner_id}-${contact.portal_contact_id}`;
    },
    ZDTicketPaginator: () => null,
    ZDTicketComments: () => null,
    ZDTicketUser: () => null,
    PartnerColors: () => null,
    ColorRGB: () => null,
    RentersImplementationPaginator: () => null,
    TuitionImplementationPaginator: () => null,
    // Renters
    SnowFlakePolicyCovered: () => null,
    SnowFlakeRentersDecisions: () => null,
    SnowFlakePolicyRentersTrends: () => null,
    SnowflakeRentersPolicyPaginator: () => null,
    SnowflakeRentersPolicy: (data) => {
      const value = data as SnowflakeRentersPolicyFragmentFragment;
      return value.POLICY_ID;
    },
    // Tuition
    SnowFlakeTuitionPolicyCovered: () => null,
    SnowFlakeTuitionDecisions: () => null,
    SnowFlakePolicyTuitionTrends: () => null,
    SnowflakeTuitionPolicyPaginator: () => null,
    SnowflakeTuitionPolicy: (data) => {
      const value = data as SnowflakeTuitionPolicyFragmentFragment;
      return `${value.POLICY_ID}`;
    },
    RefundPolicyFlow: () => null,
    SnowFlakeRefundAcknowledgmentVuPaginator: () => null,
    SnowFlakeRefundAcknowledgmentVu: (data) => {
      const value = data as SnowFlakeRefundAcknowledgmentVuFragmentFragment;
      return `${value.QUICK_USER_ID}`;
    },
    RefCodeTermPaginator: () => null,
  },
});

export default cacheExchange;
