// eslint-disable-next-line import/no-extraneous-dependencies
import { type RuntimeConfig } from 'nuxt/schema';

type environmentNames =
  | 'local'
  | 'quality-assurance'
  | 'user-acceptance-testing'
  | 'production';

const domains = {
  local: {
    gradguard: 'http://gradguard.local',
    account: 'http://account.gradguard.local',
    api: 'http://api.gradguard.local',
    cortana: 'http://cortana.gradguard.local',
    harmon: 'http://harmon.gradguard.local',
  },
  'quality-assurance': {
    gradguard: 'https://home.qa.gradguard.com',
    account: 'https://account.qa.gradguard.com',
    api: 'https://apiqa.gradguard.com',
    cortana: 'https://cortana.qa.gradguard.com',
    harmon: 'https://harmon.qa.gradguard.com',
  },
  'user-acceptance-testing': {
    gradguard: 'https://home.uat.gradguard.com',
    account: 'https://account.uat.gradguard.com',
    api: 'https://apiuat.gradguard.com',
    cortana: 'https://cortana.uat.gradguard.com',
    harmon: 'https://harmon.uat.gradguard.com',
  },
  production: {
    gradguard: 'https://gradguard.com',
    account: 'https://account.gradguard.com',
    api: 'https://api.gradguard.com',
    cortana: 'https://cortana.gradguard.com',
    harmon: 'https://harmon.internal.gradguard.com',
  },
};

export interface ENVValues {
  app_env: environmentNames,
  app_url: string,
  api_url: string,
  api_token: string,
}

export const getConfig = (allEnv: RuntimeConfig) => {
  const env = allEnv.public as unknown as ENVValues;
  const config = {
    env: env.app_env,
    isLocal: env.app_env === 'local',
    domains: domains[env.app_env],
    appURL: env.app_url,
    api: env.api_url,
    apiToken: env.api_token,
  };
  return config;
};
