export default defineAppConfig({
  ui: {
    primary: 'gg-blue',
    gray: 'slate',
    skeleton: {
      background: 'bg-gray-200 dark:bg-gray-600',
    },
    formGroup: {
      error: 'cy-error-class',
    },
    input: {
      size: {
        sm: 'text-md',
        md: 'text-md',
        lg: 'text-md',
      },
    },
    select: {
      size: {
        '2xs': 'text-md',
        xs: 'text-md',
        sm: 'text-md',
        md: 'text-md',
        lg: 'text-md',
        xl: 'text-base',
      },
    },
    selectMenu: {
      empty: 'text-md',
      option: {
        size: 'text-md',
      },
    },
    modal: {
      container: 'items-center',
      wrapper: 'z-[100]',
    },
  },
  // hack https://github.com/nuxt/icon/issues/117#issuecomment-1797833117
  nuxtIcon: {},
});
