import type {
  Data, Variables, Cache, UpdatesConfig,
} from '@urql/exchange-graphcache';

import type { PartnerContactRolesUpdateMutationVariables } from '@/graphql/pp/generated/graphql';
import invalidateQuery from '../util/invalidateQuery';

const portalContactMutations: UpdatesConfig['Mutation'] = {
  portalContactCreate: (_: Data, __: Variables, cache: Cache) => {
    invalidateQuery('portalContacts', cache);
  },
  partnerContactRolesUpdate: (_: Data, vars: PartnerContactRolesUpdateMutationVariables, cache: Cache) => {
    invalidateQuery('partnerContactRoles', cache, {
      contact_id: vars.contactId,
      partner_id: vars.partnerId,
    });
  },
};

export default portalContactMutations;
