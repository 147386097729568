import { useClientHandle } from '@urql/vue';

import { login } from '../config/pages';
import useUrqlUpdate from '@/hooks/useUrqlUpdate';
import {
  LogoutPortalContactDocument as Document,
  type LogoutPortalContactMutation as Mutation,
  type LogoutPortalContactMutationVariables as Variables,
} from '@/graphql/web/generated/graphql';
import { getConfig } from '@/config/constants';

export const useLogout = () => {
  const config = getConfig(useRuntimeConfig());
  const { onSubmitUrql, fetching } = useUrqlUpdate<Mutation, Variables>(
    useClientHandle(),
    Document,
    'logoutPortalContact',
  );
  const success = ref(false);
  const onLogout = () => onSubmitUrql({ }, {
    onSuccess: () => {
      success.value = true;
      window.location.href = login;
    },
  }, { url: `${config.api}/v2/graphql/web` });

  return {
    success,
    onLogout,
    fetching,
  };
};
