<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { computed, type HTMLAttributes } from 'vue';

import { type MenuThemeFocusable } from '../types';

const props = defineProps<{
  selected?: boolean,
  focusable: MenuThemeFocusable,
  class?: HTMLAttributes['class'],
}>();

const baseClass = computed(() => [
  props.focusable.class,
  props.selected ? props.focusable.selected : props.focusable.unselected,
]);

</script>

<template>
  <li :class="$props.class">
    <slot v-bind="{ selected, baseClass }" />
  </li>
</template>
