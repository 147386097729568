import { type PortalContactSessionFragmentFragment } from '../graphql/pp/generated/graphql';

export const usePortalContact = () => {
  const user = useState<PortalContactSessionFragmentFragment>('portal-contact');
  const setUser = (newUser: PortalContactSessionFragmentFragment) => { user.value = newUser; };
  const name = computed(() => user.value.preferred_name ?? user.value.first_name);
  return {
    user,
    setUser,
    name,
  };
};
