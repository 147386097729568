import dayjs, { Dayjs } from 'dayjs';

import type { SnowflakeTuitionRangeStatusEnum } from '~/graphql/pp/generated/graphql';

const defaultDates = () => {
  const yersterday = dayjs().subtract(1, 'day');
  return {
    defaultStartDate: yersterday.add(-1, 'year'),
    defaultEndDate: yersterday,
  };
};

export const useTuitionGlobal = () => {
  const { defaultStartDate, defaultEndDate } = defaultDates();

  const startDate = useState<Dayjs>('tuitionStartDate', () => defaultStartDate);
  const endDate = useState<Dayjs>('tuitionEndDate', () => defaultEndDate);
  const rangeStatus = useState<SnowflakeTuitionRangeStatusEnum[] | undefined>('tuitionRangeStatus');
  const purchaseInRange = useState<boolean>('tuitionRurchaseInRange');
  const activeInRange = useState<boolean>('tuitionActiveInRange');

  const dates = computed(() => ({
    start_date: startDate.value?.format('YYYY-MM-DD'),
    end_date: endDate.value?.format('YYYY-MM-DD'),
  }));
  const datesPrev = computed(() => ({
    start_date: startDate.value?.add(-1, 'year').format('YYYY-MM-DD'),
    end_date: endDate.value?.add(-1, 'year').format('YYYY-MM-DD'),
  }));

  const onUpdateDates = (newStartDate: Dayjs, newEndDate: Dayjs) => {
    startDate.value = newStartDate;
    endDate.value = newEndDate;
  };

  const onUpdateRangeStatus = (values?: SnowflakeTuitionRangeStatusEnum[]) => {
    rangeStatus.value = values;
  };

  const onUpdate = (
    newStartDate: Dayjs,
    newEndDate: Dayjs,
    purchaseInRangeValue: boolean,
    activeInRangeValue: boolean,
    rangeStatuses?: SnowflakeTuitionRangeStatusEnum[],
  ) => {
    onUpdateDates(newStartDate, newEndDate);
    onUpdateRangeStatus(rangeStatuses);
    purchaseInRange.value = purchaseInRangeValue;
    activeInRange.value = activeInRangeValue;
  };

  return {
    defaultStartDate,
    defaultEndDate,
    startDate,
    endDate,
    dates,
    datesPrev,
    onUpdateDates,
    rangeStatus,
    onUpdateRangeStatus,
    purchaseInRange,
    onUpdatePurchaseInRange: (value: boolean) => { purchaseInRange.value = value; },
    activeInRange,
    onUpdateActiveInRange: (value: boolean) => { activeInRange.value = value; },
    onUpdate,
  };
};

export type UseTuitionGlobal = ReturnType<typeof useTuitionGlobal>;
