import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

export default defineNuxtPlugin(() => {
  // when app is created in browser, restore SSR state from nuxt payload
  if (process.client) {
    dayjs.extend(localizedFormat);
    dayjs().format('L LT');
  }
});
