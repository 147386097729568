<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from '@headlessui/vue';

const isOpen = ref(false);
const closeModal = () => { isOpen.value = false; };
const openModal = () => { isOpen.value = true; };

const route = useRoute();
watch(() => route.fullPath, closeModal);

</script>
<template>
  <div>
    <button
      type="button"
      aria-label="Open menu"
      @click="openModal"
    >
      <FaIcon :icon="['fal', 'bars']" class=" text-2xl" />
    </button>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" class="relative z-[60]" @close="closeModal">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-start justify-start text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full min-h-screen max-w-sm transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all"
              >
                <button
                  type="button"
                  aria-label="Close menu"
                  class="absolute right-1 top-1 w-7 h-7 rounded-full border border-sidebar-text text-sidebar-text "
                  @click="closeModal"
                >
                  <FaIcon :icon="['fal', 'xmark']" />
                </button>
                <LayoutSidebar class="p-6" />
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
