import type { PartnerPortalContactSessionFragmentFragment } from '@/graphql/pp/generated/graphql';
import { pageMetaTypes, dashboard, login } from '../config/pages';
import useLoadApp from '../hooks/useLoadApp';
import useSaveSesionData from '../hooks/useSaveSesionData';

const redirectSession = (
  pageType?: pageMetaTypes,
  isLogged?: boolean,
  partner?: PartnerPortalContactSessionFragmentFragment,
) => {
  // Pages by default are private
  if ((!pageType || pageType === pageMetaTypes.private) && !isLogged) {
    if (window.location.pathname === '/') {
      return navigateTo({ path: login });
    }
    const currentLocation = btoa(window.location.href);
    return navigateTo({ path: login, query: { redirect: currentLocation } });
  }

  if (pageType === pageMetaTypes.session && isLogged) {
    return navigateTo(`/${partner?.partner.uuid}${dashboard}`, { replace: true });
  }
  return () => {};
};

export default defineNuxtRouteMiddleware(async (to) => {
  const pageType = to.meta.type as pageMetaTypes;
  const { user } = usePortalContact();
  const { partner } = usePartner();
  const firstLoad = useLoadInit();
  const { onSave } = useSaveSesionData();
  if (!firstLoad.value) {
    const { getSessionData } = useLoadApp();
    try {
      const response = await getSessionData();
      const { uuid } = to.params;
      onSave(response, typeof uuid === 'string' ? uuid : undefined);
    } catch (error) {
      // dont' do anything -> could be a 401 - unauthorize
      // Review it "unauthorize" will reload the page
    }
    firstLoad.value = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return redirectSession(pageType, !!user.value, partner.value) as any;
});
