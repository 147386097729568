import { type CombinedError } from '@urql/vue';

import { type PortalContactSessionFragmentFragment } from '../graphql/pp/generated/graphql';

interface PortalContactStatus {
  fetching?: boolean,
  error?: CombinedError,
  data?: PortalContactSessionFragmentFragment | null,
}
export const usePortalContactStatus = () => useState<PortalContactStatus>('portal-contact-status', () => ({
  fetching: undefined,
  error: undefined,
  data: undefined,
}));
