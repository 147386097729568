import type { Cache, Variables } from '@urql/exchange-graphcache';
// https://formidable.com/open-source/urql/docs/graphcache/custom-updates/#cacheinspectfields
/**
 * Invalidate all cache of the same query. For instance: UserPagination.
 * It will remove all the user paginations from the cache.
 * @param queryName: users -> name of the query
 * @param cache
 * @param vars
 */

const invalidateQueryCache = <V = Variables>(queryName: string, cache: Cache, vars?: V) => {
  const allFields = cache.inspectFields('Query');
  const queries = allFields.filter((x) => x.fieldName === queryName);
  queries.forEach(({ fieldName, arguments: args }) => {
    const variables = vars ?? args;
    cache.invalidate('Query', fieldName, variables as Variables);
  });
};

const invalidateQuery = <V = Variables>(
  queryName: string | string[],
  cache: Cache,
  vars?: V,
) => {
  if (typeof (queryName) === 'string') {
    invalidateQueryCache<V>(queryName, cache, vars);
  } else {
    queryName.forEach((query) => {
      invalidateQueryCache<V>(query, cache, vars);
    });
  }
};

export default invalidateQuery;
