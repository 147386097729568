import { default as index53uMx7nthnMeta } from "/home/runner/work/partner-portal/partner-portal/pages/[uuid]/index.vue?macro=true";
import { default as fileD8lLnA1ldKMeta } from "/home/runner/work/partner-portal/partner-portal/pages/[uuid]/renters/file.vue?macro=true";
import { default as marketingZGy1DOkxwSMeta } from "/home/runner/work/partner-portal/partner-portal/pages/[uuid]/renters/marketing.vue?macro=true";
import { default as reportingUnYmNO0O0vMeta } from "/home/runner/work/partner-portal/partner-portal/pages/[uuid]/renters/reporting.vue?macro=true";
import { default as _91policy_93LrAHmwHQeSMeta } from "/home/runner/work/partner-portal/partner-portal/pages/[uuid]/renters/roster/[policy].vue?macro=true";
import { default as indexu6dNrlRQP7Meta } from "/home/runner/work/partner-portal/partner-portal/pages/[uuid]/renters/roster/index.vue?macro=true";
import { default as _91user_uuid_93r1W96TOzytMeta } from "/home/runner/work/partner-portal/partner-portal/pages/[uuid]/team/[user_uuid].vue?macro=true";
import { default as indexIuInCOVX8fMeta } from "/home/runner/work/partner-portal/partner-portal/pages/[uuid]/team/index.vue?macro=true";
import { default as indexkfNH344nkwMeta } from "/home/runner/work/partner-portal/partner-portal/pages/[uuid]/tuition/refund/index.vue?macro=true";
import { default as _91plan_937KmrUBNzAjMeta } from "/home/runner/work/partner-portal/partner-portal/pages/[uuid]/tuition/roster/[plan].vue?macro=true";
import { default as indexQqnrpmyqiTMeta } from "/home/runner/work/partner-portal/partner-portal/pages/[uuid]/tuition/roster/index.vue?macro=true";
import { default as index2n1boxzmeBMeta } from "/home/runner/work/partner-portal/partner-portal/pages/index.vue?macro=true";
import { default as logingRdlhVP4XeMeta } from "/home/runner/work/partner-portal/partner-portal/pages/login.vue?macro=true";
import { default as email_45phoneeecGdzRcPpMeta } from "/home/runner/work/partner-portal/partner-portal/pages/profile/email-phone.vue?macro=true";
import { default as index5OFDlvd30GMeta } from "/home/runner/work/partner-portal/partner-portal/pages/profile/index.vue?macro=true";
import { default as passwordGUpg2hID69Meta } from "/home/runner/work/partner-portal/partner-portal/pages/profile/password.vue?macro=true";
import { default as set_45passwordfiIrEFGs7kMeta } from "/home/runner/work/partner-portal/partner-portal/pages/set-password.vue?macro=true";
import { default as _91id_93NIC8fCHTyuMeta } from "/home/runner/work/partner-portal/partner-portal/pages/support/[id].vue?macro=true";
import { default as indexipcv5OCeOmMeta } from "/home/runner/work/partner-portal/partner-portal/pages/support/index.vue?macro=true";
import { default as uivjxHoHuBzCMeta } from "/home/runner/work/partner-portal/partner-portal/pages/ui.vue?macro=true";
import { default as addTGxYWu7OziMeta } from "/home/runner/work/partner-portal/partner-portal/pages/vendors/add.vue?macro=true";
import { default as filevVkkHqA2ruMeta } from "/home/runner/work/partner-portal/partner-portal/pages/vendors/file.vue?macro=true";
import { default as listlWgyrVMyljMeta } from "/home/runner/work/partner-portal/partner-portal/pages/vendors/list.vue?macro=true";
import { default as risk_45profileHTaitWTdKUMeta } from "/home/runner/work/partner-portal/partner-portal/pages/vendors/risk-profile.vue?macro=true";
import { default as index3yQKM8t6C1Meta } from "/home/runner/work/partner-portal/partner-portal/pages/welcome/index.vue?macro=true";
export default [
  {
    name: "uuid",
    path: "/:uuid()",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/[uuid]/index.vue")
  },
  {
    name: "uuid-renters-file",
    path: "/:uuid()/renters/file",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/[uuid]/renters/file.vue")
  },
  {
    name: "uuid-renters-marketing",
    path: "/:uuid()/renters/marketing",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/[uuid]/renters/marketing.vue")
  },
  {
    name: "uuid-renters-reporting",
    path: "/:uuid()/renters/reporting",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/[uuid]/renters/reporting.vue")
  },
  {
    name: "uuid-renters-roster-policy",
    path: "/:uuid()/renters/roster/:policy()",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/[uuid]/renters/roster/[policy].vue")
  },
  {
    name: "uuid-renters-roster",
    path: "/:uuid()/renters/roster",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/[uuid]/renters/roster/index.vue")
  },
  {
    name: "uuid-team-user_uuid",
    path: "/:uuid()/team/:user_uuid()",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/[uuid]/team/[user_uuid].vue")
  },
  {
    name: "uuid-team",
    path: "/:uuid()/team",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/[uuid]/team/index.vue")
  },
  {
    name: "uuid-tuition-refund",
    path: "/:uuid()/tuition/refund",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/[uuid]/tuition/refund/index.vue")
  },
  {
    name: "uuid-tuition-roster-plan",
    path: "/:uuid()/tuition/roster/:plan()",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/[uuid]/tuition/roster/[plan].vue")
  },
  {
    name: "uuid-tuition-roster",
    path: "/:uuid()/tuition/roster",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/[uuid]/tuition/roster/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logingRdlhVP4XeMeta || {},
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/login.vue")
  },
  {
    name: "profile-email-phone",
    path: "/profile/email-phone",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/profile/email-phone.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/profile/index.vue")
  },
  {
    name: "profile-password",
    path: "/profile/password",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/profile/password.vue")
  },
  {
    name: "set-password",
    path: "/set-password",
    meta: set_45passwordfiIrEFGs7kMeta || {},
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/set-password.vue")
  },
  {
    name: "support-id",
    path: "/support/:id()",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/support/[id].vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/support/index.vue")
  },
  {
    name: "ui",
    path: "/ui",
    meta: uivjxHoHuBzCMeta || {},
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/ui.vue")
  },
  {
    name: "vendors-add",
    path: "/vendors/add",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/vendors/add.vue")
  },
  {
    name: "vendors-file",
    path: "/vendors/file",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/vendors/file.vue")
  },
  {
    name: "vendors-list",
    path: "/vendors/list",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/vendors/list.vue")
  },
  {
    name: "vendors-risk-profile",
    path: "/vendors/risk-profile",
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/vendors/risk-profile.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: index3yQKM8t6C1Meta || {},
    component: () => import("/home/runner/work/partner-portal/partner-portal/pages/welcome/index.vue")
  }
]