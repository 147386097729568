import { type PartnerPortalContactSessionFragmentFragment } from '@/graphql/pp/generated/graphql';

export const usePartners = () => {
  const partners = useState<PartnerPortalContactSessionFragmentFragment[]>('partners');
  const setPartners = (value: PartnerPortalContactSessionFragmentFragment[]) => { partners.value = value; };
  const getPartnerByUUID = (uuid: string) => partners.value.find((currentPartner) => currentPartner.partner.uuid === uuid);
  return {
    partners,
    setPartners,
    getPartnerByUUID,
  };
};
