<script setup lang="ts">
import dayjs from 'dayjs';

withDefaults(defineProps<{
  logoClass?: string,
  logoParentClass?: string,
  oneColor?: boolean
}>(), {
  logoClass: 'h-5',
  logoParentClass: 'mt-2 mb-3',
});
const currentYear = dayjs().year();
</script>

<template>
  <div class="space-y-1.5">
    <div class="text-sm ">
      Powered by
    </div>
    <div :class="logoParentClass">
      <SvgGradGuard
        :class="[logoClass, 'block']"
        :one-color="oneColor"
      />
    </div>
    <div class="text-xs">
      Copyright © 2009-{{ currentYear }} - GradGuard
    </div>
  </div>
</template>
