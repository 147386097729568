<script setup lang="ts" generic="ItemValue extends ItemMenuBaseSubmenu">
import { type ItemMenuBaseSubmenu, type MenuTheme, isSelectedInSubmenu } from '../types';
import UISubmenu from './SubMenu.vue';

const props = defineProps<{
  themeSubMenu: MenuTheme['subMenu'],
  themeSubMenuItem: MenuTheme['subMenuItem'],
  item: ItemMenuBaseSubmenu, // it should be ItemValue -> but it doens't work :(
  selected?: string,
}>();

const isSelected = computed(() => (props.selected === props.item.key
  || isSelectedInSubmenu(props.selected, props.item.submenu)
));
</script>
<template>
  <UISubmenu
    :classes="themeSubMenu"
    :selected="isSelected"
  >
    <template #title="args">
      <slot :name="`item_${item.key}`" v-bind="{ item, ...args }" />
    </template>
    <template v-if="item.submenu" #default="args">
      <slot
        :name="`sub_menu_${item.key}`"
        v-bind="{ submenu: item.submenu, ...args }"
      />
    </template>
  </UISubmenu>
</template>
