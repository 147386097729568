<!-- eslint-disable vue/max-len -->
<template>
  <p>
    <strong>
      Effective 2/21/24
    </strong>
  </p>
  <p>
    <strong>
      GradGuard PartnerPortal Terms of Service <br />
      Your use of the PartnerPortal (as defined below) constitutes your consent to this Agreement.
    </strong>
  </p>
  <p>
    These Terms of Service (the “Agreement”) is between you and Next Generation Insurance Group, LLC (doing business as GradGuard) (“GradGuard,” “our,” “we,” or “us”) concerning your access to and use of the PartnerPortal. This Agreement hereby incorporates by this reference any additional terms and conditions posted by GradGuard through the PartnerPortal, or otherwise made available to you by GradGuard.
  </p>
  <p>
    By using the PartnerPortal, you confirm that you have reached the age of majority in your jurisdiction, and are legally capable to enter into binding agreements such as this Agreement. If you are an individual accessing or using the PartnerPortal on behalf of, or for the benefit of, any corporation, partnership or other entity with which you are associated (an “Organization”), then you are agreeing to this Agreement on behalf of yourself and such Organization, and you represent and warrant that you have the legal authority to bind such Organization to this Agreement. References to “you” and “your” in this Agreement will refer to both the individual using the PartnerPortal and to any such Organization.
  </p>
  <p>
    THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION THAT, AS FURTHER SET FORTH BELOW IN SECTION 12, REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR ANY OTHER COURT PROCEEDINGS, OR CLASS ACTIONS OF ANY KIND.
  </p>

  <p>
    BY ENTERING INTO THIS AGREEMENT, YOU ACKNOWLEDGE THAT YOU UNDERSTAND THIS AGREEMENT AND AGREE TO ALL OF ITS TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT USE OR ACCESS THE PARTNERPORTAL.
  </p>

  <h2>
    1. Scope
  </h2>
  <p>
    1.1 Services. GradGuard provides renters and tuition insurance programs and services (the “Services”), administered and accessible to any person(s) registered to access and use the Services (the “Client(s)”) via multiple websites, mobile applications, and other means, including an online platform referred to herein as the “PartnerPortal” (all such websites, mobile applications, and other means, including the PartnerPortal, collectively, the “GradGuard Platform”).
  </p>
  <p>
    1.2 PartnerPortal. The GradGuard PartnerPortal is a digital service that helps schools manage and monitor the performance of the Services. Schools may view the participation reports, the Certificate of Insurance, and a record of policy acknowledgment dates that enables school officials to verify disclosure of insurance policies (liability and refund), insurance coverage, and compliance requirements. For the purposes of this Agreement, “Certificate of Insurance” means a document issued by the insurance company that provides evidence of the insurance policies offered through the PartnerPortal, including their terms, conditions, coverages, and policy limits.
  </p>
  <p>
    1.3 Institutional Partners. You are an authorized school official, college or university employee, professional, or any other person who registers an account with GradGuard (“Partner(s)”). You represent and warrant that you possess the rights to view, download, and/or distribute the reports, policy acknowledgment, information, and all resident data contained within the PartnerPortal.
  </p>

  <h2>
    2. Access and Use of the PartnerPortal and Resident Documentation.
  </h2>
  <p>
    2.1 License to Resident Documentation. Subject to and conditioned on your compliance with the terms and conditions of this Agreement, GradGuard hereby grants you a non-exclusive, non-sublicensable, non-transferable, revocable license to use documentation uploaded by or on behalf of a Client to the GradGuard Platform (“Resident Documentation”) solely for purposes of helping manage and monitor the performance of the GradGuard Services. GradGuard reserves the right to immediately revoke this license if (i) you breach any terms and conditions of this Agreement, (ii) if you fail to comply with relevant laws or regulations, (iii) if GradGuard reasonably believes that your use of the PartnerPortal could harm GradGuard’s reputation or operations, or (iv) if your access to the PartnerPortal is terminated or suspended pursuant to Section 8.
  </p>
  <p>
    2.2 Restrictions. You may not: (a) modify, decompile, dissemble, reverse-engineer, reproduce, redistribute, create derivative works based upon, or attempt to commercially gain from your use or misuse of the PartnerPortal or Resident Documentation, or any of their components, except as permitted by the Agreement; (b) remove, obscure, or modify any copyright or other intellectual property notices that appear on or in the PartnerPortal; (c) use the PartnerPortal or the Resident Documentation in any manner (i) that violates any law or regulation (including any laws regarding the export of data or software to and from the US or other countries), this Agreement, any of GradGuard’s policies, or any third party policy, that applies to you; (ii) that harms GradGuard, the Clients, our service providers, our suppliers, other users of the PartnerPortal, or any other person; or (iii) to impersonate or attempt to impersonate GradGuard, a GradGuard employee, other users of the PartnerPortal, or any other person or entity; (d) automatedly crawl or query the PartnerPortal for any purpose or by any means (including screen and database scraping, spiders, robots, crawlers and any other automated activity with the purpose of obtaining information from the PartnerPortal) unless you have received prior express written permission from us. If you violate the terms and conditions of this Agreement, your permission to access and use the PartnerPortal is automatically revoked.
  </p>
  <h2>
    3. IP and Feedback
  </h2>
  <p>
    3.1 Ownership of IP. We and our licensors own all right, title, and interest, including all intellectual property rights and business methods, in and to the Services, the GradGuard Platform (the “Company IP”) and Resident Documentation. We and our licensors reserve all rights not expressly granted to you in this Agreement and nothing in this Agreement will be construed as granting, by implication, estoppel, or otherwise, any license or right in or to the Company IP or Resident Documentation.
  </p>
  <p>
    3.2 Feedback. If you provide us any ideas, proposals, suggestions or other materials (“Feedback”), whether related to Company IP, Resident Documentation or otherwise, you hereby acknowledge and agree that such Feedback is not confidential, your provision of such Feedback is gratuitous, unsolicited, and without restriction, and we may (but have no obligation to) use such Feedback for any purpose whatsoever without any attribution or compensation to you or any third party.
  </p>
  <h2>
    4. Fees and Audit
  </h2>
  <p>
    4.1 Fees. GradGuard may waive fees to the PartnerPortal for our Partners at our sole discretion. If applicable, we will provide you with an invoice, which will set forth the total amount due and preferred payment method in connection with your access and use of the PartnerPortal. You will pay us the invoiced amount within thirty (30) days from the invoice date. You will make all payments hereunder in US dollars on or before the due date. If you fail to make any payment when due, without limiting our other rights and remedies, we may, after providing you with a written notice and a 10-day cure period, suspend your access to any portion of the PartnerPortal until such amounts are paid in full. All fees and other amounts payable by you under this Agreement are exclusive of taxes and similar assessments. You are responsible for all sales, use, and excise taxes, and any other similar taxes, duties, and charges of any kind on any amounts payable by you hereunder, other than any taxes imposed on our income.
  </p>
  <p>
    4.2 Partner Credentials Audit. We may from time to time require you to provide additional documentation evidencing that you remain in good standing and/or in compliance with your Partner Credentials. “Partner Credentials” means the valid and accurate information and documentation provided by you, relevant to your partnership with us. You will provide such documentation within 10 business days upon receiving such request from us. Failure to provide such documentation within 10 business days will constitute a material breach of this Agreement.
  </p>
  <h2>
    5. Disclaimers
  </h2>
  <p>
    5.1 THE PARTNERPORTAL IS PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED, OR STATUTORY. TO THE FULLEST EXTENT PERMITTED BY LAW, GRADGUARD EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESSED, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR PARTICULAR PURPOSE, COMPATIBILITY, SECURITY, OR ACCURACY WITH RESPECT TO THE COMPANY IP AND RESIDENT DOCUMENTATION, INCLUDING THE USE THEREOF. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A VIRUS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE PARTNERPORTAL OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PARTNERPORTAL OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT. GRADGUARD DOES NOT WARRANT THAT THE PARTNERPORTAL WILL BE ERROR-FREE OR UNINTERRUPTED.
  </p>
  <p>
    5.2 GradGuard expressly disclaims all warranties for information posted or transmitted by Clients, including any Resident Documentation. You agree to bear all risks associated with the downloading or transmitting of materials and utilizing the PartnerPortal and Resident Documentation, including reliance on the accuracy, reliability, or legality of such materials.
  </p>
  <h2>
    6. Limitations of Liability
  </h2>
  <p>
    TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (a) WE WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF ANY KIND, UNDER ANY CONTRACT, TORT (EXCLUDING GROSS NEGLIGENCE AND WILLFUL MISCONDUCT), STRICT LIABILITY OR OTHER THEORY, INCLUDING DAMAGES FOR LOSS OF PROFITS, USE OR DATA, OR LOSS OF OTHER INTANGIBLES, EVEN IF ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES; (b) WITHOUT LIMITING THE FOREGOING, WE WILL NOT BE LIABLE FOR DAMAGES OF ANY KIND RESULTING FROM YOUR USE OF OR INABILITY TO USE THE PARTNERPORTAL, RESIDENT DOCUMENTATION OR THIRD-PARTY MATERIALS, INCLUDING FROM ANY VIRUS THAT MAY BE TRANSMITTED IN CONNECTION THEREWITH; (c) YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE PARTNERPORTAL IS TO STOP USING THE PARTNERPORTAL; AND (d) OUR MAXIMUM AGGREGATE LIABILITY FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, WILL BE THE AGGREGATE AMOUNT OF FEES PAID BY YOU IN THE 24 MONTHS PRECEDING THE EVENT GIVING RISE TO THE CLAIM. ALL LIMITATIONS OF LIABILITY OF ANY KIND ARE MADE FOR THE BENEFIT OF US AND OUR RESPECTIVE SUCCESSORS AND ASSIGNS.
  </p>
  <h2>
    7. Indemnification
  </h2>
  <p>
    You agree to defend, indemnify, and hold GradGuard, and its respective agents, employees, directors, officers, and representatives harmless from and against all claims and expenses (including, without limitation, reasonable attorneys’ fees and costs) arising out of (a) your failure to comply with applicable laws, regulations, this Agreement, any GradGuard policies in which you engage on or in the PartnerPortal, including the GradGuard Services.
  </p>
  <h2>
    8. Termination and Suspension
  </h2>
  <p>
    We may terminate or suspend your access to any portion or all of the PartnerPortal for any or no reason. We will use commercially reasonable efforts to provide you with notice of any such termination or suspension, but we will otherwise not be held liable for any consequences that may incur as a result of that termination or suspension. In the case of termination or expiration of this Agreement, the license and access right granted to you under Section 2 will be immediately terminated.
  </p>
  <h2>
    9. Representations, Warranties, and Covenants
  </h2>
  <p>
    9.1 General. You represent, warrant, and covenant that: <br />
    (a) you have the ability to enter into this Agreement and grant all assignments, licenses, and permissions contemplated or contained herein;
    <br />
    (b) your use of the PartnerPortal and provision of GradGuard Services will be in compliance with all laws, regulations, this Agreement, and all GradGuard policies;
    <br />
    (c) all documentation and information provided by you relating to your Partner Credentials are true, accurate and complete; and
    <br />
    (d) your Partner Credentials are valid and accurate and you will immediately notify us if your Partner Credentials are revoked, terminated, suspended, or otherwise no longer valid.
  </p>

  <h2>
    10. Privacy and Information Security.
  </h2>
  <p>
    10.1 Privacy Policy. Your submission of information through the PartnerPortal is governed by our data privacy policy, located at https://gradguard.com/privacy-policy (the “Privacy Policy”). You represent and warrant that any information you provide in connection with the PartnerPortal is and will remain accurate and complete and that you will maintain and update such information as needed. GradGuard agrees to comply with all applicable data protection laws and regulations with respect to any personal data obtained or processed in connection with this Agreement. For more information, please refer to our Privacy Policy.
  </p>
  <p>
    10.2 Your Systems. You have and will retain sole responsibility for your information technology infrastructure, whether operated directly by you or through the use of third-party services (“Systems”), and all access to and use of the PartnerPortal and other Company IP we provide to you, directly or indirectly by or through your Systems or your access credentials, including all results obtained from, and all conclusions, decisions, and actions based on, such access or use. You will employ all necessary safeguards (physical, administrative, technical and otherwise) to protect against any unauthorized access to or use of the PartnerPortal or Resident Documentation.
  </p>
  <h2>
    11. Confidentiality
  </h2>
  <p>
    While using the PartnerPortal you may receive certain private or confidential information, including information about Clients and Resident Documentation, from us or a Client (“Confidential Information”). Except to comply with law, regulation, or other legal process, or upon prior written consent by the Client (with respect to Resident Documentation or other non-public information of such Client), you will not publish, disseminate, or disclose, for your own benefit or the benefit of any third party, any Confidential Information. You will not engage in any activity which violates the privacy of any Client. Your failure to comply with the foregoing will constitute a material breach of this Agreement.
  </p>
  <h2>
    12. Governing Law; Dispute Resolution
  </h2>
  <p>
    This Agreement is governed by and will be construed in accordance with the laws of the State of Arizona, without regard to its principles of conflicts of law, and regardless of your location. Any claim arising out of or related to this Agreement, including claims related to the parties’ negotiations and inducements to enter into this Agreement, will be resolved by final and binding arbitration under the rules of JAMS in effect when the arbitration is filed (the “JAMS Rules”). Each party waives any right to adjudicate any dispute in any other court or forum, except that a party may seek interim relief before the start of arbitration as allowed by the JAMS Rules. The arbitration will be held in Phoenix, Arizona, and utilize a single arbitrator chosen and mutually agreed to by the parties. The parties will abide by the arbitrator’s decision and any court having jurisdiction may enforce it. If any legal action, dispute, or other proceeding arises or is commenced to interpret, enforce or recover damages for the breach of any term of this Agreement, the prevailing party will be entitled to recover reasonable attorney’s fees and costs incurred in connection with such action, in addition to costs of suit.
  </p>
  <h2>
    13. Changes to this Agreement
  </h2>
  <p>
    We have the right, in our sole discretion, to update this Agreement from time to time. All modifications are effective immediately when we notify you thereof. Your continued use of the PartnerPortal following the effective date of any modifications to this Agreement will be deemed an acceptance of the modified Agreement. You are expected to check this page frequently so you are aware of any modifications, as they are binding on you.
  </p>
  <h2>
    14. Miscellaneous
  </h2>
  <p>
    This Agreement constitutes the entire agreement and understanding between you and us with respect to the subject matter hereof and supersedes all prior and contemporaneous understandings and agreements, both written and oral, with respect to such subject matter. The invalidity, illegality, or unenforceability of any provision in this Agreement does not affect any other provision in this Agreement or the validity, legality, or enforceability of such provision in any other jurisdiction. Our failure to act by with respect to a breach of this Agreement by you or others does not constitute a waiver and will not limit our rights with respect to such breach or any subsequent breaches. Neither party shall be liable for any failure or delay in performing its obligations under this Agreement due to unforeseen circumstances or causes beyond its reasonable control, including, but not limited to, acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, earthquakes, accidents, strikes, or shortages of transportation, facilities, fuel, energy, labor or materials. This Agreement is personal to you and may not be assigned or transferred for any reason without our prior written consent, and any action or conduct in violation the foregoing will be void and without effect. We may assign, transfer, or sublicense any or all of our rights or obligations under this Agreement without restriction. Any heading, caption, or section title contained herein is for convenience only, and in no way defines or explains any section or provision. All terms defined in the singular shall have the same meanings when used in the plural, where appropriate and unless otherwise specified. Any use of the term “including” or variations thereof in this Agreement will be construed as if followed by the phrase “without limitation.”
  </p>
</template>
