import { getConfig } from '../config/constants';

const useCsrfCookieFetch = () => {
  const config = getConfig(useRuntimeConfig());
  const data = useFetch(
    `${config.domains.api}/v2/sanctum/csrf-cookie`,
    {
      server: false,
      credentials: 'include',
    },
  );
  return data;
};

export default useCsrfCookieFetch;
