import type {
  Data, Variables, Cache, UpdatesConfig,
} from '@urql/exchange-graphcache';

import invalidateQuery from '../util/invalidateQuery';

const zendeskMutations: UpdatesConfig['Mutation'] = {
  zdTicketCommentCreate: (_: Data, __: Variables, cache: Cache) => {
    invalidateQuery('zdTicketComments', cache);
  },
  zdTicketCreate: (_: Data, __: Variables, cache: Cache) => {
    invalidateQuery('zdTickets', cache);
  },
};

export default zendeskMutations;
