// eslint-disable-next-line import/no-extraneous-dependencies
import type { HTMLAttributes } from 'vue';

export interface MenuThemeFocusable {
  class: HTMLAttributes['class'],
  selected: HTMLAttributes['class'],
  unselected: HTMLAttributes['class'],
}

export interface MenuThemeOpenable extends MenuThemeFocusable {
  open: HTMLAttributes['class'],
  openSelected: HTMLAttributes['class'],
  openUnSelected: HTMLAttributes['class'],
}
export interface MenuTheme {
  class: HTMLAttributes['class'],
  li: {
    class: HTMLAttributes['class'],
    /** Element focusable like <button> or <a>  */
    focusable: MenuThemeFocusable,
  },
  subMenu: {
    ul: HTMLAttributes['class'],
    li: HTMLAttributes['class'],
    button: MenuThemeOpenable,
  },
  subMenuItem: {
    class: HTMLAttributes['class'],
    /** Element focusable like <button> or <a>  */
    focusable: MenuThemeFocusable,
  }
}

export type BaseMenu = {
  id?: string,
  key:string,
  title: string,
}

export type ItemMenuBaseSubmenu<
  ExtraBaseButton extends BaseMenu = BaseMenu,
  ExtraBaseSubmenu extends BaseMenu = BaseMenu,
> = ExtraBaseButton & { submenu?: ExtraBaseSubmenu[] };

export type ItemBaseMenu<
  ExtraBase extends BaseMenu = BaseMenu,
  ExtraBaseButton extends BaseMenu = BaseMenu,
  ExtraBaseSubmenu extends BaseMenu = BaseMenu,
> = ExtraBase | ItemMenuBaseSubmenu<ExtraBaseButton, ExtraBaseSubmenu>;

export const isSelectedInSubmenu = (selected?: string, subMenus?: ItemBaseMenu[]) => !!subMenus?.find((subMenu) => subMenu.key === selected);

export const theme: MenuTheme = {
  class: '',
  li: {
    class: 'mb-2',
    focusable: {
      class: 'px-4 py-2 font-light rounded-xl flex space-x-2 w-full',
      selected: 'bg-primary text-white',
      unselected: 'hover:bg-primary-100 dark:hover:bg-slate-900',
    },
  },
  subMenu: {
    li: 'mb-2',
    ul: 'mt-3',
    button: {
      class: 'flex items-center px-4 py-2 font-light rounded-xl space-x-2 w-full',
      selected: 'bg-primary text-white',
      unselected: 'hover:bg-primary-100 dark:hover:bg-slate-900',
      open: '',
      openSelected: '',
      openUnSelected: 'bg-primary-100 dark:bg-slate-900',
    },
  },
  subMenuItem: {
    class: 'mb-4',
    focusable: {
      class: 'flex items-center space-x-2 text-sm flex-1 pr-3 py-1.5 rounded-r-lg group-hover:bg-primary-100  dark:group-hover:bg-slate-900',
      selected: 'text-primary',
      unselected: '',
    },
  },
};
