<template>
  <div v-for="error in errorsArray" :key="error" :class="$props.class">
    {{ error }}
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  errors: Record<string, string[]>,
}>();

const errorsArray = computed(() => {
  const valErrors = props.errors;
  const arrayErrors: string[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [, keyErrors] of Object.entries(valErrors)) {
    keyErrors.forEach((value) => {
      arrayErrors.push(value);
    });
  }
  return arrayErrors;
});
</script>
