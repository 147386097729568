<script setup lang="ts">
import { MenuItem } from '@headlessui/vue';

defineProps<{
  icon?: string[],
  title?: string,
}>();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
defineEmits<{(event: 'click', payload: MouseEvent): void }>();
const getButtonClass = (active: boolean) => [
  active ? 'bg-primary text-white' : 'dark:bg-slate-700 bg-white',
  'group flex w-full items-center px-2 py-2 text-sm text-left',
];
const getIconClass = (active: boolean) => [
  active ? 'bg-primary text-white' : 'dark:bg-slate-700 bg-white',
  'ml-2 mr-1.5 h-5 w-5 hover:text-gg-blue-800',
];
</script>

<template>
  <MenuItem v-slot="{ active }">
    <slot
      v-bind="{
        active,
        buttonClass: getButtonClass(active),
        iconClass: getIconClass(active),
      }"
    >
      <button
        :class="getButtonClass(active)"
        type="button"
        @click="$emit('click', $event)"
      >
        <FaIcon
          v-if="icon"
          :icon="icon"
          :class="getIconClass(active)"
        />
        <slot name="title">
          {{ title }}
        </slot>
      </button>
    </slot>
  </MenuItem>
</template>
