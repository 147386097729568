<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import type { HTMLAttributes } from 'vue';

defineProps<{
  onLogout:() => void,
  classMobile?: HTMLAttributes['class'],
}>();
const { name } = usePortalContact();
</script>
<template>
  <div class="flex items-center justify-between space-x-3">
    <div :class="['flex items-center space-x-3 flex-1', classMobile]">
      <LayoutHeaderMobileSidebar />
      <div class="flex-1 flex items-center">
        <LayoutSidebarSchool />
      </div>
    </div>
    <div class="" />
    <LayoutHeaderProfile :name="name" :logout="onLogout" />
  </div>
</template>
