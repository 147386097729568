<script setup lang="ts" generic="T extends Record<string, any>">
import type { ObjectSchema } from 'yup';
import type { FormSubmitEvent, Form, FormError } from '#ui/types';

interface Form2Props {
  state: Partial<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema?: ObjectSchema<any, T, any, ''>,
  onSuccess:(value: FormSubmitEvent<T>) => void,
  onError?:(errors: FormError[]) => void,
  focusOnFail?: boolean,
}

const props = defineProps<Form2Props>();

const form = ref<Form<T>>();
const onErrorInternal = (value: { errors : FormError[] }) => {
  if (props.onError) {
    props.onError(value.errors);
  }
  if (props.focusOnFail) {
    const { path } = value.errors[0];
    const element = document.querySelector(`input[name="${path}"]`) as HTMLInputElement;
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      element.focus();
    }
  }
};
</script>
<template>
  <UForm
    ref="form"
    :schema="schema"
    :state="state"
    @submit="onSuccess"
    @error="onErrorInternal"
  >
    <slot />
  </UForm>
</template>
