<script setup lang="ts">
import type { PartnerPortalContactSessionFragmentFragment } from '@/graphql/pp/generated/graphql';
import { dashboard } from '@/config/pages';

const router = useRouter();
const { partner, setPartner } = usePartner();
const { partners } = usePartners();
const setLocalPartner = (value: PartnerPortalContactSessionFragmentFragment) => {
  setPartner(value);
  router.push(`/${value.partner.uuid}${dashboard}`);
};
</script>
<template>
  <div class="flex-1">
    <LayoutSidebarPartnerMenu
      v-if="partners.length > 1"
      :partner="partner"
      :partners="partners"
      :set-partner="setLocalPartner"
    />
    <SchoolLogo
      v-else
      :name="partner.partner.school.school_name"
      :logo="partner.partner.school.logo_url"
      class="h-12"
    />
  </div>
</template>
