<script setup lang="ts">
import useLoadApp from './hooks/useLoadApp';

const { cookie, session } = useLoadApp();
</script>

<template>
  <UNotifications role="status" />
  <div v-if="cookie.status.value === 'pending'">
    ...loading csrf-cookie
  </div>
  <div v-else-if="cookie.error.value">
    error: {{ cookie.error.value }}
  </div>
  <div v-else-if="session.fetching">
    ...loading session
  </div>
  <div v-else>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
