import {
  type AnyVariables, type ClientHandle, CombinedError, type OperationContext,
} from '@urql/vue';
import { type DocumentNode } from 'graphql';

const useUrqlUpdate = <Mutation, Variables extends AnyVariables>(
  urql: ClientHandle,
  document: DocumentNode,
  name: keyof Mutation,
) => {
  const toast = useToast();
  const mutation = urql.useMutation<Mutation, Variables>(document);
  const onSubmitUrql = async (
    variables: Variables,
    actions?: {
      onError?: (value: CombinedError) => void,
      onSuccess?: (value: Mutation) => void,
    },
    context?: Partial<OperationContext>,
  ) => {
    const response = await mutation.executeMutation(variables, context);
    if (response.data && response.data[name]) {
      if (actions?.onSuccess) {
        actions?.onSuccess(response.data);
      } else {
        toast.add({ title: 'Changes saved' });
      }
    } else if (response.error) {
      if (actions?.onError) {
        actions?.onError(response.error);
      } else {
        toast.add({ title: response.error.message });
      }
    }
    return response;
  };
  return {
    ...mutation,
    onSubmitUrql,
  };
};

export default useUrqlUpdate;
