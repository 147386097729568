import { Client } from '@urql/vue';

import {
  type PortalContactLoggedQuery,
  PortalContactLoggedDocument,
  type PortalContactSessionFragmentFragment,
} from '../graphql/pp/generated/graphql';

interface UseSessionDataProps {
  onFulfilled?: (session: PortalContactSessionFragmentFragment) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRejected?: (reason: any) => void,
}

export const useSessionData = () => {
  const session = usePortalContactStatus();

  const app = useNuxtApp();
  const urql = app.$urql as Client;

  const get = (props: UseSessionDataProps) => urql.query<PortalContactLoggedQuery>(PortalContactLoggedDocument, {}).then(
    (portalContact) => {
      session.value.fetching = false;
      session.value.data = portalContact.data?.portalContactLogged;
      if (portalContact.data?.portalContactLogged && props.onFulfilled) {
        props.onFulfilled(portalContact.data?.portalContactLogged);
      } else if (props.onRejected) {
        props.onRejected(portalContact.error);
      }
      return portalContact;
    },
    (reason) => {
      session.value.fetching = false;
      session.value.error = reason;
      if (props.onRejected) {
        props.onRejected(reason);
      }
    },
  );

  return {
    get,
    session,
  };
};

const useLoadApp = () => {
  const cookie = useCsrfCookie();
  const { session, get } = useSessionData();
  const getSessionData = (props: UseSessionDataProps = {}) => new Promise<PortalContactSessionFragmentFragment>(
    (resolve, reject) => {
      cookie.value.finally(() => get({
        onFulfilled: (portalContact) => {
          if (props.onFulfilled) {
            props.onFulfilled(portalContact);
          }
          resolve(portalContact);
        },
        onRejected: (reason) => {
          if (props.onRejected) {
            props.onRejected(reason);
          }
          reject(reason);
        },
      }));
    },
  );

  return {
    cookie,
    session,
    getSessionData,
  };
};

export default useLoadApp;
