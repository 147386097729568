<template>
  <svg
    viewBox="0 0 1524 292"
    fill="none"
    :class="$props.class"
    aria-hidden="true"
  >
    <path :fill="colors.green" d="M1488.34 42.0601H1468.94V103.49C1458.16 92.6501 1444.3 86.3301 1430.84 86.3301C1397.79 86.3301 1370.9 117.23 1370.9 155.21C1370.9 193.19 1397.79 224.08 1430.84 224.08C1444.26 224.08 1458.13 217.79 1468.94 207.01V221.67H1490.78V42.0601H1488.34ZM1431.32 202.24C1410.05 202.24 1392.74 181.13 1392.74 155.2C1392.74 129.27 1410.05 108.16 1431.32 108.16C1452.59 108.16 1469.91 129.26 1469.91 155.2C1469.91 181.14 1452.6 202.24 1431.32 202.24Z" />
    <path :fill="colors.green" d="M1511.48 65.6599C1504.95 65.6599 1499.86 60.5699 1499.86 54.0399C1499.86 47.5099 1504.95 42.4199 1511.48 42.4199C1518.01 42.4199 1523.1 47.5099 1523.1 54.0399C1523.1 60.5699 1517.97 65.6599 1511.48 65.6599ZM1511.48 43.9499C1505.9 43.9499 1501.39 48.4499 1501.39 54.0399C1501.39 59.6299 1505.89 64.1299 1511.48 64.1299C1517.07 64.1299 1521.57 59.6299 1521.57 54.0399C1521.57 48.4499 1517.03 43.9499 1511.48 43.9499ZM1514.55 60.7599L1511.32 55.1799H1509.65V60.7599H1507.56V47.3099L1512.2 47.3399C1514.42 47.3399 1516.41 48.7099 1516.41 51.2199C1516.41 53.7299 1514.68 54.6799 1513.44 54.9399L1517.06 60.7499H1514.55V60.7599ZM1511.45 49.0699H1509.65V53.4399H1511.45C1512.89 53.4399 1514.26 53.0199 1514.26 51.2499C1514.26 49.4799 1512.89 49.0599 1511.45 49.0599V49.0699Z" />
    <path :fill="colors.blue" d="M683.01 87.1699H670.3C654.3 87.1699 641.07 92.0799 631.65 101.43V86.8899H610.08V220.43H631.65V150.95C631.65 124.52 646.09 108.74 670.3 108.74H672.45L673.18 107.71L685 91.0199L687.72 87.1699H683.01Z" />
    <path :fill="colors.blue" d="M719.5 87.1699H699.71L698.98 88.1899L687.04 104.88L684.28 108.74H719.5C731.27 108.74 751.01 112.09 751.01 134.55V135.65H724.24C692.35 135.65 666.41 155.2 666.41 179.22C666.41 203.24 690.23 222.8 719.5 222.8C730.27 222.8 741.52 219.18 751.01 212.77V220.43H772.57V134.55C772.57 105.78 751.74 87.1699 719.5 87.1699ZM724.25 157.21H751.02V180.29C750.21 191.96 736.36 201.24 719.51 201.24C702.66 201.24 687.99 191.36 687.99 179.22C687.99 167.08 704.94 157.21 724.25 157.21Z" />
    <path :fill="colors.green" d="M1257.36 87.27H1237.56L1236.83 88.28L1224.88 104.99L1222.12 108.85H1257.35C1269.13 108.85 1288.9 112.21 1288.9 134.69V135.79H1262.11C1230.19 135.79 1204.23 155.35 1204.23 179.41C1204.23 203.47 1228.06 223.01 1257.35 223.01C1268.13 223.01 1279.39 219.39 1288.9 212.96V220.64H1310.48V134.69C1310.48 105.89 1289.63 87.28 1257.35 87.28L1257.36 87.27ZM1262.12 157.36H1288.91V180.47C1288.09 192.15 1274.23 201.43 1257.36 201.43C1240.49 201.43 1225.82 191.54 1225.82 179.4C1225.82 167.26 1242.78 157.36 1262.11 157.36H1262.12Z" />
    <path :fill="colors.green" d="M1194.28 89.6599H1176.71V167.76C1176.71 186.94 1160.5 203.16 1141.33 203.16C1124.47 203.16 1110.77 189.45 1110.77 172.59V89.6599H1090.76V172.59C1090.76 200.48 1113.45 223.17 1141.33 223.17C1153.69 223.17 1166.79 217.84 1176.71 209V220.75H1196.73V89.6599H1194.29H1194.28Z" />
    <path :fill="colors.blue" d="M592.4 135.65H517.12V157.48H573.01V185.34C559.42 197.18 542.08 203.69 524.04 203.69C483.33 203.69 450.21 170.56 450.21 129.85C450.21 89.14 483.33 56.0099 524.04 56.0099C542.64 56.0099 559.76 62.57 573.58 74.98L576.25 77.3799L578.03 78.98L579.66 77.23L591.14 64.9L592.82 63.0999L591 61.4399L588.26 58.9599C570.69 42.9899 547.88 34.2 524.04 34.2C471.28 34.2 428.36 77.1199 428.36 129.87C428.36 182.62 471.28 225.55 524.04 225.55C541.45 225.55 558.28 220.93 573.01 212.15V221.68H594.84V135.67H592.4V135.65Z" />
    <path :fill="colors.blue" d="M899.41 42.0601H880.01V103.49C869.23 92.6501 855.37 86.3301 841.91 86.3301C808.86 86.3301 781.98 117.23 781.98 155.21C781.98 193.19 808.86 224.08 841.91 224.08C855.34 224.08 869.2 217.79 880.01 207.01V221.67H901.85V42.0601H899.41ZM842.39 202.24C821.11 202.24 803.81 181.13 803.81 155.2C803.81 129.27 821.11 108.16 842.39 108.16C863.67 108.16 880.97 129.26 880.97 155.2C880.97 181.14 863.66 202.24 842.39 202.24Z" />
    <path :fill="colors.green" d="M1075.45 135.64H1000.17V157.47H1056.06V185.33C1042.48 197.17 1025.13 203.68 1007.1 203.68C966.38 203.68 933.26 170.56 933.26 129.85C933.26 89.1399 966.38 56.0099 1007.1 56.0099C1025.69 56.0099 1042.82 62.5699 1056.64 74.9799L1059.31 77.3799L1061.09 78.9699L1062.72 77.2199L1074.2 64.8899L1075.88 63.0899L1074.05 61.4299L1071.32 58.9399C1053.75 42.9699 1030.94 34.1799 1007.1 34.1799C954.34 34.1799 911.42 77.0999 911.42 129.85C911.42 182.6 954.35 225.52 1007.1 225.52C1024.5 225.52 1041.34 220.9 1056.06 212.13V221.66H1077.9V135.65H1075.46L1075.45 135.64Z" />
    <path :fill="colors.green" d="M1398.24 87.7499L1398.58 87.2699H1381.15C1365.14 87.2699 1351.91 92.1699 1342.47 101.54V86.8899H1320.89V220.64H1342.47V151.1C1342.47 126.78 1354.71 111.51 1375.5 109.19C1381.56 100.25 1389.32 92.9099 1398.24 87.7499Z" />
    <path :fill="colors.green" d="M128.03 198.46C131.07 196.58 133.23 193.48 133.54 189.84C133.97 184.71 130.64 180.13 125.7 178.34V130.29H116.63V178.45C112.42 180.03 109.25 183.57 108.85 188.03C108.48 192.65 111.09 196.8 115.19 198.93C104.83 208.22 104.1 239.93 104.64 261.39C104.64 261.39 105.43 266.13 123.05 266.13C140.67 266.13 144.06 261.39 144.06 261.39C144.06 261.39 141.86 209.6 128.03 198.46Z" />
    <path :fill="colors.blue" d="M408.55 68.69L202.83 0L0 70.17L204.66 181.32L408.55 68.69Z" />
    <path :fill="colors.blue" d="M327.58 185.21C327.07 176.91 325.8 146.95 324.61 127.75L204.71 193.98L136.8 157.1V172.16C142.17 176.77 145.2 183.62 144.6 190.76C144.35 193.65 143.55 196.41 142.26 198.92C148.43 210.02 151.67 226.34 153.38 239.96C175.45 250.17 195.11 259.2 197.63 260.2C201.04 261.55 203.35 261.68 204.62 261.62V261.65C204.72 261.65 204.83 261.62 204.93 261.61C205.43 261.57 205.77 261.52 205.77 261.52V261.5C207.41 261.33 209.18 260.95 211.21 260.33C220.4 257.55 317.5 207.17 321.58 204.74C325.64 202.33 327.97 197.69 327.58 185.2V185.21Z" />
    <path :fill="colors.blue" d="M100.89 200.22C98.55 196.34 97.41 191.79 97.78 187.14C98.3 181.27 101.12 176.03 105.52 172.27V140.12L86.34 129.7C85.17 147.95 83.08 180.35 82.78 185.25C82.36 192.12 80.66 206.29 91.9 211.44C93.21 212.05 94.85 212.81 96.58 213.62C97.71 208.43 99.12 203.92 100.89 200.22Z" />
    <template v-if="showText">
      <path :fill="colors.blue" d="M1003.16 272.75L1002.64 272.25L1002.13 272.76C1000.05 274.85 997.26 276 994.3 276C987.79 276 983.06 270.9 983.06 263.88C983.06 256.86 987.89 251.76 994.3 251.76C997.32 251.76 1000.1 252.91 1002.13 254.99L1002.63 255.51L1003.16 255L1004.85 253.37L1005.38 252.85L1004.86 252.33C1002.11 249.51 998.36 247.97 994.29 247.97C985.72 247.97 979.25 254.81 979.25 263.88C979.25 272.95 985.71 279.8 994.29 279.8C998.36 279.8 1002.11 278.25 1004.86 275.42L1005.38 274.9L1004.85 274.39L1003.16 272.75Z" />
      <path :fill="colors.blue" d="M1022.46 247.97C1013.89 247.97 1007.43 254.81 1007.43 263.88C1007.43 272.95 1013.89 279.8 1022.46 279.8C1031.03 279.8 1037.5 272.96 1037.5 263.88C1037.5 254.8 1031.03 247.97 1022.46 247.97ZM1022.46 276C1015.95 276 1011.22 270.9 1011.22 263.88C1011.22 256.86 1016.05 251.76 1022.46 251.76C1028.87 251.76 1033.7 256.97 1033.7 263.88C1033.7 270.79 1028.97 276 1022.46 276Z" />
      <path :fill="colors.blue" d="M1046.41 233.94H1044.07H1043.34V234.68V278.48V279.21H1044.07H1046.41H1047.15V278.48V234.68V233.94H1046.41Z" />
      <path :fill="colors.blue" d="M1058.21 233.94H1055.88H1055.15V234.68V278.48V279.21H1055.88H1058.21H1058.94V278.48V234.68V233.94H1058.21Z" />
      <path :fill="colors.blue" d="M1078.66 247.97C1070.75 247.97 1064.79 254.81 1064.79 263.88C1064.79 272.95 1070.75 279.8 1078.66 279.8C1083.56 279.8 1087.42 277.83 1090.45 273.77L1090.86 273.23L1090.34 272.78L1088.53 271.21L1087.93 270.69L1087.46 271.32C1085.06 274.55 1082.34 275.99 1078.65 275.99C1073.4 275.99 1069.37 271.82 1068.65 265.78H1092.52V263.88C1092.52 254.8 1086.56 247.97 1078.65 247.97H1078.66ZM1068.66 261.97C1069.39 255.92 1073.42 251.76 1078.66 251.76C1083.9 251.76 1087.92 255.93 1088.6 261.97H1068.65H1068.66Z" />
      <path :fill="colors.blue" d="M1122.4 248.55H1119.34V253.35C1116.81 250.06 1113.04 247.97 1109.38 247.97C1101.8 247.97 1095.62 255.11 1095.62 263.88C1095.62 272.65 1101.8 279.8 1109.38 279.8C1113.02 279.8 1116.79 277.73 1119.34 274.44V278.18C1119.34 284.31 1115.64 287.97 1109.44 287.97C1105.01 287.97 1101.89 286.75 1100.05 285.71L1099.41 285.35L1099.05 285.99L1097.99 287.91L1097.69 288.47L1098.21 288.85C1098.37 288.97 1102.23 291.74 1109.45 291.74C1117.64 291.74 1123.14 286.28 1123.14 278.16V248.52H1122.41L1122.4 248.55ZM1109.5 276C1103.95 276 1099.43 270.56 1099.43 263.88C1099.43 257.2 1103.95 251.76 1109.5 251.76C1115.05 251.76 1119.57 257.2 1119.57 263.88C1119.57 270.56 1115.06 276 1109.5 276Z" />
      <path :fill="colors.blue" d="M1142.68 247.97C1134.77 247.97 1128.81 254.81 1128.81 263.88C1128.81 272.95 1134.77 279.8 1142.68 279.8C1147.58 279.8 1151.44 277.83 1154.47 273.77L1154.88 273.23L1154.37 272.78L1152.56 271.21L1151.96 270.69L1151.49 271.32C1149.09 274.55 1146.38 275.99 1142.68 275.99C1137.44 275.99 1133.4 271.82 1132.68 265.78H1156.55V263.88C1156.55 254.8 1150.59 247.97 1142.68 247.97ZM1132.68 261.97C1133.41 255.92 1137.44 251.76 1142.68 251.76C1147.92 251.76 1151.96 255.93 1152.63 261.97H1132.68Z" />
      <path :fill="colors.blue" d="M1180.54 233.94H1178.2H1177.48V234.68V278.48V279.21H1178.2H1180.54H1181.27V278.48V234.68V233.94H1180.54Z" />
      <path :fill="colors.blue" d="M1190.01 248.55H1189.28V249.28V278.48V279.21H1190.01H1192.34H1193.07V278.48V249.28V248.55H1192.34H1190.01Z" />
      <path :fill="colors.darkBlue" d="M1192.34 236.28H1190.01H1189.28V237.01V242.85V243.58H1190.01H1192.34H1193.07V242.85V237.01V236.28H1192.34Z" />
      <path :fill="colors.blue" d="M1218.73 236.15C1216.96 234.38 1214.52 233.37 1212.03 233.37C1206.79 233.37 1202.54 237.62 1202.54 242.86V248.55H1197.87V252.35H1202.54V279.21H1206.34V252.35H1214.52V248.55H1206.34V242.86C1206.34 239.72 1208.89 237.16 1212.04 237.16C1213.52 237.16 1214.94 237.73 1216.04 238.77L1216.54 239.25L1217.04 238.78L1218.73 237.2L1219.28 236.68L1218.75 236.15H1218.73Z" />
      <path :fill="colors.blue" d="M1228.79 247.97C1220.88 247.97 1214.92 254.81 1214.92 263.88C1214.92 272.95 1220.88 279.8 1228.79 279.8C1233.69 279.8 1237.56 277.83 1240.59 273.77L1240.99 273.23L1240.48 272.78L1238.67 271.21L1238.07 270.69L1237.6 271.32C1235.19 274.55 1232.47 275.99 1228.78 275.99C1223.54 275.99 1219.51 271.82 1218.78 265.78H1242.65V263.88C1242.65 254.8 1236.68 247.97 1228.78 247.97H1228.79ZM1218.79 261.97C1219.53 255.92 1223.56 251.76 1228.79 251.76C1234.02 251.76 1238.07 255.93 1238.73 261.97H1218.78H1218.79Z" />
      <path :fill="colors.blue" d="M1277.17 247.97C1273.47 247.97 1269.7 250.04 1267.21 253.31V248.55H1263.41V291.48H1267.21V274.49C1269.72 277.74 1273.48 279.8 1277.17 279.8C1284.75 279.8 1290.92 272.66 1290.92 263.88C1290.92 255.1 1284.75 247.97 1277.17 247.97ZM1287.12 263.88C1287.12 270.56 1282.6 276 1277.05 276C1271.5 276 1266.97 270.56 1266.97 263.88C1266.97 257.2 1271.49 251.76 1277.05 251.76C1282.61 251.76 1287.12 257.2 1287.12 263.88Z" />
      <path :fill="colors.blue" d="M1310.64 247.97C1306.07 247.97 1302.58 249.62 1300.39 252.79V248.55H1296.59V279.21H1300.39V262.89C1300.39 255.92 1304.22 251.76 1310.64 251.76H1311.37V247.97H1310.64Z" />
      <path :fill="colors.blue" d="M1325.83 247.97C1317.25 247.97 1310.79 254.81 1310.79 263.88C1310.79 272.95 1317.25 279.8 1325.83 279.8C1334.41 279.8 1340.87 272.96 1340.87 263.88C1340.87 254.8 1334.4 247.97 1325.83 247.97ZM1325.83 276C1319.31 276 1314.59 270.9 1314.59 263.88C1314.59 256.86 1319.42 251.76 1325.83 251.76C1332.24 251.76 1337.07 256.97 1337.07 263.88C1337.07 270.79 1332.35 276 1325.83 276Z" />
      <path :fill="colors.blue" d="M1356.2 247.97H1351.09V236.08L1350.19 236.3L1347.85 236.88L1347.3 237.02V247.97H1342.04V251.76H1347.3V270.31C1347.3 275.21 1351.3 279.21 1356.21 279.21H1356.94V275.41H1356.21C1353.4 275.41 1351.1 273.12 1351.1 270.31V251.76H1356.94V247.97H1356.21H1356.2Z" />
      <path :fill="colors.blue" d="M1372.8 247.97C1364.89 247.97 1358.93 254.81 1358.93 263.88C1358.93 272.95 1364.89 279.8 1372.8 279.8C1377.7 279.8 1381.57 277.83 1384.6 273.77L1385 273.23L1384.49 272.78L1382.68 271.21L1382.09 270.69L1381.62 271.32C1379.22 274.55 1376.49 275.99 1372.8 275.99C1367.55 275.99 1363.52 271.82 1362.8 265.78H1386.67V263.88C1386.67 254.8 1380.71 247.97 1372.81 247.97H1372.8ZM1362.8 261.97C1363.53 255.92 1367.55 251.76 1372.8 251.76C1378.05 251.76 1382.07 255.93 1382.75 261.97H1362.8Z" />
      <path :fill="colors.blue" d="M1413.67 272.75L1413.15 272.25L1412.64 272.76C1410.55 274.85 1407.78 276 1404.81 276C1398.3 276 1393.57 270.9 1393.57 263.88C1393.57 256.86 1398.4 251.76 1404.81 251.76C1407.87 251.76 1410.58 252.88 1412.63 254.99L1413.14 255.51L1413.67 255L1415.36 253.37L1415.89 252.85L1415.37 252.33C1412.63 249.51 1408.87 247.97 1404.81 247.97C1396.24 247.97 1389.78 254.81 1389.78 263.88C1389.78 272.95 1396.24 279.8 1404.81 279.8C1408.87 279.8 1412.63 278.25 1415.37 275.42L1415.89 274.9L1415.36 274.39L1413.67 272.75Z" />
      <path :fill="colors.blue" d="M1430.69 247.97H1425.59V236.08L1424.68 236.3L1422.34 236.88L1421.79 237.02V247.97H1416.53V251.76H1421.79V270.31C1421.79 275.21 1425.78 279.21 1430.69 279.21H1431.42V275.41H1430.69C1427.88 275.41 1425.59 273.12 1425.59 270.31V251.76H1431.43V247.97H1430.7H1430.69Z" />
      <path :fill="colors.blue" d="M1447.28 247.97C1439.37 247.97 1433.41 254.81 1433.41 263.88C1433.41 272.95 1439.37 279.8 1447.28 279.8C1452.18 279.8 1456.05 277.83 1459.08 273.77L1459.49 273.23L1458.97 272.78L1457.17 271.21L1456.57 270.69L1456.1 271.32C1453.69 274.55 1450.97 275.99 1447.28 275.99C1442.03 275.99 1438.01 271.82 1437.28 265.78H1461.14V263.88C1461.14 254.8 1455.18 247.97 1447.27 247.97H1447.28ZM1437.28 261.97C1438.01 255.92 1442.03 251.76 1447.28 251.76C1452.53 251.76 1456.56 255.93 1457.23 261.97H1437.28Z" />
      <path :fill="colors.blue" d="M1491.03 233.94H1487.96V253.33C1485.43 250.05 1481.67 247.96 1478 247.96C1470.42 247.96 1464.25 255.1 1464.25 263.87C1464.25 272.64 1470.42 279.79 1478 279.79C1481.65 279.79 1485.41 277.72 1487.96 274.45V279.2H1491.76V233.94H1491.03ZM1478.12 275.99C1472.57 275.99 1468.05 270.55 1468.05 263.87C1468.05 257.19 1472.57 251.75 1478.12 251.75C1483.67 251.75 1488.2 257.19 1488.2 263.87C1488.2 270.55 1483.68 275.99 1478.12 275.99Z" />
    </template>
  </svg>
</template>

<script setup lang="ts">
const logoColors = {
  green: '#8DC63F',
  blue: '#005596',
  darkBlue: '#104A82',
};

const currentColor = {
  green: 'currentColor',
  blue: 'currentColor',
  darkBlue: 'currentColor',
};

const props = withDefaults(
  defineProps<{
    alt?: string,
    oneColor?: boolean,
    showText?: boolean,
  }>(),
  { alt: 'GradGuard' },
);

const colors = computed(() => (props.oneColor
  ? currentColor
  : logoColors));
</script>
