import dayjs from 'dayjs';

import { type PartnerPortalContactSessionFragmentFragment } from '@/graphql/pp/generated/graphql';

const getRgbColors = (value: { r: number, g: number, b: number, }) => `${value.r} ${value.g} ${value.b}`;
const getCssColors = (partner: PartnerPortalContactSessionFragmentFragment) => `:root {
  --color-sidebar-bg: ${getRgbColors(partner.partner.colors.bg)};
  --color-sidebar-text: ${getRgbColors(partner.partner.colors.text)};
  --color-sidebar-menu-focus: ${getRgbColors(partner.partner.colors.menuFocus)};
  --color-sidebar-menu-selected-bg: ${getRgbColors(partner.partner.colors.menuSelectedBg)};
  --color-sidebar-menu-selected-text: ${getRgbColors(partner.partner.colors.menuSelectedText)};
  --color-sidebar-menu-text: ${getRgbColors(partner.partner.colors.menuText)};
  --color-sidebar-menu-hover: ${getRgbColors(partner.partner.colors.menuHover)};
  --color-sidebar-menu-hover-text:  ${getRgbColors(partner.partner.colors.menuHoverText)};
  --color-sidebar-logo-color:  ${getRgbColors(partner.partner.colors.logoColor ?? partner.partner.colors.menuText)};
}`;

const storageKeyRoot = 'sidebar-partner-root';

const updateStyle = (colors: string) => {
  const element = document.getElementById(storageKeyRoot);
  if (element) {
    element.innerHTML = colors;
  } else {
    const style = document.createElement('style');
    style.innerHTML = colors;
    style.id = storageKeyRoot;
    document.head.appendChild(style);
  }
};

export const usePartner = () => {
  const partner = useState<PartnerPortalContactSessionFragmentFragment>('partner');
  const hasTuition = computed(() => !!partner?.value.partner.school.tuition_contract_id);
  const hasRenters = computed(() => !!partner?.value.partner.school.renters_contract_id);
  const tuitionRights = computed(() => !!partner?.value.partner.sessionRights?.find((role) => role.code === 'TROSTER'));
  const rentersRights = computed(() => !!partner?.value.partner.sessionRights?.find((role) => role.code === 'RROSTER'));
  const adminRights = computed(() => !!partner?.value.partner.sessionRights?.find((role) => role.code === 'USERCRTN'));
  const { setValidStartDate, onUpdateActiveInRange: onActiveInRange } = useRentersGlobal();
  const { onUpdateDates, onUpdateActiveInRange } = useTuitionGlobal();
  const setPartner = (value: PartnerPortalContactSessionFragmentFragment) => {
    if (!partner.value || value.id !== partner.value.id) {
      partner.value = value;
      updateStyle(
        getCssColors(partner.value),
      );
      onActiveInRange(true);
      onUpdateActiveInRange(true);
      setValidStartDate(value.partner.school.primaryRefCode?.currentRentersImplementation?.effective_date ?? undefined);
      const startDate = value.partner.school.primaryRefCode?.lastTerm?.term_start;
      const endDate = value.partner.school.primaryRefCode?.lastTerm?.term_end;
      if (startDate && endDate) {
        onUpdateDates(dayjs(startDate), dayjs(endDate));
      }
    }
  };
  return {
    partner: computed(() => partner.value),
    setPartner,
    hasTuition,
    hasRenters,
    tuitionRights,
    rentersRights,
    adminRights,
  };
};
