import Bugsnag from '@bugsnag/js';

import type { PortalContactSessionFragmentFragment, PartnerPortalContactSessionFragmentFragment } from '../graphql/pp/generated/graphql';

const getPartner = (data: PortalContactSessionFragmentFragment, uuid?: string) => {
  const firstPartner = data.partnerPortalContactsActive[0];
  if (uuid) {
    const partner = data.partnerPortalContactsActive.find((p) => p.partner.uuid === uuid);
    return partner ?? firstPartner;
  }
  if (data.partner_id_default) {
    const partner = data.partnerPortalContactsActive.find((p) => p.id === data.partner_id_default);
    return partner ?? firstPartner;
  }
  return firstPartner;
};

export interface OnSaveReturn {
  partner: PartnerPortalContactSessionFragmentFragment,
  partners: PartnerPortalContactSessionFragmentFragment[],
  user: PortalContactSessionFragmentFragment,
}

const useSaveSesionData = () => {
  const { setUser } = usePortalContact();
  const { setPartner } = usePartner();
  const { setPartners } = usePartners();
  const onSave = (user: PortalContactSessionFragmentFragment, uuid?: string): OnSaveReturn => {
    Bugsnag.addOnError((event) => { event.addMetadata('session', user); });
    setUser(user);
    if (user.partnerPortalContactsActive.length === 0) {
      throw new Error('Partner at least should have a `ref_code` associated');
    }
    const partner = getPartner(user, uuid);
    setPartners(user.partnerPortalContactsActive);
    setPartner(partner);
    return {
      partner,
      partners: user.partnerPortalContactsActive,
      user,
    };
  };
  return { onSave };
};

export default useSaveSesionData;
