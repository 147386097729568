import {
  createClient,
  errorExchange,
  CombinedError,
  type Operation,
  fetchExchange,
  ssrExchange,
} from '@urql/vue';
import { devtoolsExchange } from '@urql/devtools';

import cacheExchange from './cache';
import { PortalContactLoggedDocument } from '../generated/graphql';
import { login } from '../../../config/pages';

/* All 401 response will be redirect to login page except useAgentLoggedQuery query  */
const onError = (error: CombinedError, operation: Operation) => {
  const operationBody = operation.query.loc?.source.body ?? '';
  const isGetLogged = PortalContactLoggedDocument.loc?.source.body === operationBody;
  // IntrospectionQuery should not be send
  // https://formidable.com/open-source/urql/docs/graphcache/schema-awareness/#integrating-a-schema
  const isIntrospectionQuery = operationBody.search('query IntrospectionQuery') >= 0;
  if (!isIntrospectionQuery && !isGetLogged && error.response?.status === 401) {
    const currentLocation = btoa(window.location.href);
    // Reload web page to remove all URQL cache
    const url = `${login}?redirect=${currentLocation}`;
    window.location.href = url;
  }
};

export const ssr = ssrExchange({
  isClient: process.client,
});

const getClient = (domain: string) => createClient({
  url: `${domain}/v2/graphql/partner-portal`,
  fetchOptions: () => ({
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    },
    credentials: 'include',
  }),
  exchanges: [
    devtoolsExchange,
    cacheExchange,
    errorExchange({ onError }),
    ssr, // Add `ssr` in front of the `fetchExchange`
    fetchExchange,
  ],
});

export default getClient;
